import { createSelector } from 'reselect';
import get from 'lodash-es/get';
import { initialState } from './reducer';

export const selectTokenContainerDomain = (state: any) => state.authToken || initialState;

export const selectUser = () => createSelector(selectTokenContainerDomain, (substate) => get(substate, 'user'));

export const selectAuthError = () =>
  createSelector(selectTokenContainerDomain, (substate) => get(substate, 'authError'));

export const selectLoading = () =>
  createSelector(selectTokenContainerDomain, (substate) => get(substate, 'authLoading'));

export const selectAutoLoginLoading = () =>
  createSelector(selectTokenContainerDomain, (substate) => get(substate, 'autoLoginLoading'));

export const selectAddSitesLoading = () =>
  createSelector(selectTokenContainerDomain, (substate) => get(substate, 'addSitesLoading'));

export const selectRedirect = () => createSelector(selectTokenContainerDomain, (substate) => get(substate, 'redirect'));
