import { UserSites } from '@app/containers/TokenContainer/types';

export const getCustomToken = async (code: string): Promise<{ token?: string; error?: string }> => {
  const resp = await fetch(`https://us-central1-tuesday-labs.cloudfunctions.net/signInWithWebflow?code=${code}`);
  const jsonResponse = await resp.json();
  return jsonResponse;
};

export const getWebflowSites = async (
  code: string,
  userId: string
): Promise<{ sites?: UserSites[]; error?: string }> => {
  const resp = await fetch(
    `https://us-central1-tuesday-labs.cloudfunctions.net/updateUserWebflowSites?code=${code}&userId=${userId}`
  );
  const jsonResponse = await resp.json();
  return jsonResponse;
};
