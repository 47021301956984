import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const app = initializeApp({
  // Your Firebase configuration object
  apiKey: 'AIzaSyA-WOaG1rGf_oUBOp3-_3lBNSzvRrjRtjw',
  authDomain: 'tuesday-labs.firebaseapp.com',
  projectId: 'tuesday-labs',
  storageBucket: 'tuesday-labs.appspot.com',
  messagingSenderId: '728699275211',
  appId: '1:728699275211:web:e563bbc41623861eef184e',
  measurementId: 'G-5713YSJJZN'
});

export const auth = getAuth(app);
export const firebase = getDatabase(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export default app;
