import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash-es/get';
import { prepare } from '@app/utils';

export const initialState = {
  loadingEditor: false,
  errorEditor: null,
  projectDetails: null,
  loadingUpdateStyles: false,
  errorUpdatingStyles: null,
  hellobarStyles: null,
  templates: null,
  loadingTemplates: true,
  loadingWebsitePreview: false
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    requestGetProject: {
      reducer: (state) => {
        state.loadingEditor = true;
      },
      prepare
    },
    successGetProject(state, action) {
      state.projectDetails = action.payload.project;
      state.hellobarStyles = action.payload.helloBarStyles;
      state.loadingEditor = false;
      state.errorEditor = null;
    },
    failureGetProject(state, action) {
      state.loadingEditor = false;
      state.projectDetails = null;
      state.errorEditor = get(action.payload, 'message', 'something_went_wrong');
    },
    requestUpdateStyles: {
      reducer: (state) => {
        state.loadingUpdateStyles = true;
      },
      prepare
    },
    successUpdateStyles(state) {
      state.loadingUpdateStyles = false;
      state.errorUpdatingStyles = null;
    },
    failureUpdateStyles(state, action) {
      state.loadingUpdateStyles = false;
      state.errorUpdatingStyles = get(action.payload, 'message', 'something_went_wrong');
    },
    requestGetTemplates: {
      reducer: (state) => {
        state.loadingTemplates = true;
      },
      prepare
    },
    successGetTemplates(state, action) {
      state.loadingTemplates = false;
      state.templates = action.payload;
    },
    failureGetTemplates(state) {
      state.loadingTemplates = false;
      state.templates = null;
    },
    requestGetWebsitePreview: {
      reducer: (state) => {
        state.loadingWebsitePreview = true;
      },
      prepare
    },
    successGetWebsitePreview(state, action) {
      state.loadingWebsitePreview = false;
      state.projectDetails = action.payload;
    },
    failureWebsitePreview(state) {
      state.loadingWebsitePreview = false;
    },
    requestProjectInBackground: {
      reducer: () => {},
      prepare
    },
    successGetProjectInBackground(state, action) {
      state.projectDetails = action.payload;
    }
  }
});

export const {
  requestGetProject,
  successGetProject,
  failureGetProject,
  requestUpdateStyles,
  successUpdateStyles,
  failureUpdateStyles,
  requestGetTemplates,
  successGetTemplates,
  failureGetTemplates,
  requestGetWebsitePreview,
  successGetWebsitePreview,
  failureWebsitePreview,
  requestProjectInBackground,
  successGetProjectInBackground
} = editorSlice.actions;

export default editorSlice.reducer;
