import { firestore } from '../firebase-config';
import { setDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { UserAuth, UserStore } from '@app/containers/TokenContainer/types';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';

export async function addUser(uidData: string, name: string, email: string) {
  try {
    await setDoc(doc(firestore, 'users', uidData), {
      uid: uidData,
      name: name,
      signUpStatus: false,
      email: email,
      subscriber: false,
      app1: false,
      app2: false,
      app3: false,
      app4: false,
      app5: false
    });
  } catch (err) {
    return err;
  }
}

export async function lastSignupPhase(uidData: any, designation: any, company: any) {
  try {
    await updateDoc(doc(firestore, 'users', uidData), {
      designation: designation,
      companyName: company,
      signUpStatus: true
    });
  } catch (err) {
    return err;
  }
}
export async function unsubscribeFlag(emailData: any) {
  try {
    await setDoc(doc(firestore, 'unsubscribe', emailData), {
      email: emailData,
      emailSubscriber: false
    });
  } catch (err) {
    return err;
  }
}

export async function getUser(userAuth: UserAuth): Promise<UserStore> {
  const usersRef = await getDoc(doc(firestore, 'users', userAuth.uid));
  const userFirestoreData = (await usersRef.data()) as UserStore;

  return { uid: userAuth.uid, email: userAuth.email, ...userFirestoreData };
}

export const getWebsitePreviewUrl = async (
  userId: string,
  projectId: string,
  websiteUrl: string
): Promise<{ url?: string; error?: string }> => {
  const resp = await fetch(
    `https://us-central1-tuesday-labs.cloudfunctions.net/getWebsitePreview?userId=${userId}&projectId=${projectId}&websiteUrl=${websiteUrl}`
  );
  const jsonResponse = await resp.json();
  return jsonResponse;
};

export async function handleResetPassword(auth: any, actionCode: any, newPassword: string) {
  verifyPasswordResetCode(auth, actionCode)
    .then(() => {
      confirmPasswordReset(auth, actionCode, newPassword)
        //.then((resp) => {
        //   return resp;
        // })
        .catch((error) => {
          return error;
        });
    })
    .catch((error) => {
      return error;
    });
}
