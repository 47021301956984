export const HEADER_HEIGHT = '7rem';
export const MIN_SIDEBAR_WIDTH = '4.5rem';
export const MOBILE_DRAWER_BREAKPOINT = 450;
export const RECOMMENDED_EVENTS_GA = {
  SIGNUP: 'sign_up',
  LOGIN: 'login'
};
export const CUSTOM_EVENTS_ACTIONS_GA = {
  REGISTRATION_COMPLETE: 'REGISTRATION COMPLETE',
  COPY_SCRIPT: 'COPY SCRIPT'
};
export const CUSTOM_EVENTS_CATEGORY_GA = {
  HELLO_BAR: 'HELLO BAR'
};

export const HELP_URL = 'https://tuesday-labs.freshdesk.com/support/home';
