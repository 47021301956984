/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import React, { useEffect } from 'react';
import GlobalStyle from '@app/global-styles';
import { routeConfig } from '@app/routeConfig';
import { Layout } from 'antd';
import map from 'lodash-es/map';
import { withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import { AnyAction, compose } from 'redux';
import styled, { ThemeProvider } from 'styled-components';
import For from '@components/For';
import { colors } from '@themes/index';
import { HEADER_HEIGHT, MIN_SIDEBAR_WIDTH } from '@app/utils/constants';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import saga from '@containers/TokenContainer/saga';
import { injectSaga } from 'redux-injectors';
import { auth } from '@app/firebase-config';
import { failureAutoLogin, requestAutoLogin } from '../TokenContainer/reducer';
import { ProtectedRoute } from '@app/components';
import { selectUser } from '../TokenContainer/selectors';
import { UserAuth, UserStore } from '../TokenContainer/types';
import { logoutAction } from '@app/reducers';
import { trackGaPageView } from '@app/services/googleAnalytics';
import { Auth0Provider } from '@auth0/auth0-react';

type AppType = {
  user: UserStore;
  dispatchAutoLogin: (payload: UserAuth) => AnyAction;
  dispatchAutoLoginError: (error: Error) => AnyAction;
  dispatchLogout: () => AnyAction;
  location: { pathname: string; search: string; hash: string };
};

const theme = {
  fg: colors.primary,
  bg: colors.secondaryText,
  headerHeight: HEADER_HEIGHT,
  sidebarWidth: MIN_SIDEBAR_WIDTH
};

const CustomLayout = styled(Layout)`
  && {
    flex-direction: row;
  }
`;

export function App({ user, dispatchAutoLogin, dispatchAutoLoginError, dispatchLogout, location }: AppType) {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      (authUser: unknown) => {
        if (authUser) {
          dispatchAutoLogin(authUser as UserAuth);
        } else {
          dispatchLogout();
        }
      },
      (error) => {
        dispatchAutoLoginError(error);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Insert pageview to send current route details for ga
    trackGaPageView(location);
  }, [location]);

  return (
    <Auth0Provider
      domain="webflow.com/oauth"
      clientId="d337421872e93b588b7541124454eff9ab52c16575fd148c05122abe5ea19651"
      authorizationParams={{
        scope: 'code'
      }}
      skipRedirectCallback={true}
    >
      <ThemeProvider theme={theme}>
        <CustomLayout>
          <Layout.Content>
            <For
              ParentComponent={(props) => <Switch {...props} />}
              of={map(Object.keys(routeConfig))}
              renderItem={(routeKey, index) => {
                const Component = routeConfig[routeKey].component;
                return (
                  <ProtectedRoute
                    isLoggedIn={!!user}
                    fullySignedUp={user?.signUpStatus}
                    exact={routeConfig[routeKey].exact!}
                    key={index}
                    path={routeConfig[routeKey].route!}
                    render={(props) => {
                      const updatedProps = {
                        ...props,
                        ...routeConfig[routeKey].props
                      };
                      return <Component {...updatedProps} />;
                    }}
                  />
                );
              }}
            />
            <GlobalStyle />
          </Layout.Content>
        </CustomLayout>
      </ThemeProvider>
    </Auth0Provider>
  );
}

export function mapDispatchToProps(dispatch: (arg0: AnyAction) => any) {
  return {
    dispatchAutoLogin: (payload: any) => dispatch(requestAutoLogin(payload)),
    dispatchAutoLoginError: (error: any) => dispatch(failureAutoLogin(error)),
    dispatchLogout: () => dispatch(logoutAction())
  };
}

export const mapStateToProps = createStructuredSelector({
  user: selectUser()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect, injectSaga({ key: 'tokenProvider', saga }))(App) as React.FC;

export const AppTest = App;
