import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize(process.env.GA_TRACKING_CODE as string);
};

export const trackGaPageView = (location: { pathname: string; search: string; hash: string }) => {
  ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search + location.hash });
};

export const recommendedEventGA = (name: string) => {
  ReactGA.event(name);
};

/**
 * Event - Add custom tracking event.
 * @author naeem
 * @date 2022-02-24
 * @param {string} category
 * @param {string} action
 * @param {number} value
 * @param {string} label
 */
type eventType = {
  category: string;
  action: string;
  value?: number;
  label?: string;
};

export const customEventGA = ({ category, action, value, label }: eventType) => {
  ReactGA.event({
    category,
    action,
    value,
    label
  });
};
