import { createGlobalStyle } from 'styled-components';
import { colors } from '@app/themes';
import DMSansRegular from './font/DMSansRegular.ttf';
import DMSansMedium from './font/DMSansMedium.ttf';
import DMSansBold from './font/DMSansBold.ttf';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansRegular});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansBold});
    font-weight: 700;
    font-style: normal;
  }

  html,
  body {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'DM Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'DM Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: ${colors.secondaryText};
    min-height: 100%;
    min-width: 100%;
  }

  p,
  span,
  button,
  label {
    font-family: 'DM Sans';
    line-height: 1.5em;
    margin-bottom: 0;
  }

  .ant-popover-inner {
    background: black;
    border: 1px solid ${colors.neutral50};
  }

  .ant-popover-inner .ant-popover-inner-content {
    background: ${colors.neutral80};
  }

  .ant-popover-inner .ant-popover-inner-content .sketch-picker {
    background: ${colors.neutral80};
  }

  .ant-space-item .sketch-picker {
    width: 100%
  }

  .ant-message-custom-content {
    display: flex;
  }
`;

export default GlobalStyle;
