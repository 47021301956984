/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */
const primary = '#000000';
const primary70 = '#E2E4FD';
const primary80 = '#B1B7FB';
const primary90 = '#7781F8';
const primary100 = '#515EF6';
const primary110 = '#3D4BF5';
const neutral00 = '#FEFEFE';
const neutral10 = '#F2F3F5';
const neutral20 = '#DEE0E3';
const neutral30 = '#B6BAC0';
const neutral40 = '#52555C';
const neutral50 = '#4D4D4D';
const neutral60 = '#3E3E3E';
const neutral70 = '#3A3C40';
const neutral80 = '#313338';
const neutral90 = '#2B2D31';
const avatar1 = '#CF4343';
const avatar2 = '#D08015';
const avatar4 = '#10828F';
const avatar5 = '#296FB4';
const avatar6 = '#6267DE';
const avatar7 = '#637A88';
const avatar8 = '#7CA00D';
const avatar9 = '#925ACF';
const avatar10 = '#DA1F77';
const text = '#212529';
const secondaryText = '#FFFFFF';
const secondary = '#b0b0b0';
const success = '#28a745';
const error = '#dc3545';
const gotoStories = '#1890ff';
const cardBg = '#d8d8d8';
const darkBlack = '#0F0F10';
const lightBlack = '#2e2e2e';
const colors = {
  primary70,
  primary80,
  primary90,
  primary100,
  primary110,
  neutral00,
  neutral10,
  neutral20,
  neutral30,
  neutral40,
  neutral50,
  neutral60,
  neutral70,
  neutral80,
  neutral90,
  avatar1,
  avatar2,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  transparent: 'rgba(0,0,0,0)',
  // Example colors:
  text,
  primary,
  secondary,
  success,
  error,
  secondaryText,
  gotoStories,
  cardBg,
  darkBlack,
  lightBlack,
  theme: {
    lightMode: {
      primary,
      secondary
    },
    darkMode: {
      primary: secondary,
      secondary: primary
    }
  }
};
module.exports = colors;
