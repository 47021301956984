export type RouteConstant = {
  route: string;
  exact?: boolean;
  isProtected?: boolean;
  props?: object;
};

const routeConstants: Record<string, RouteConstant> = {
  dashboard: {
    route: '/',
    exact: true,
    isProtected: true
  },
  login: {
    route: '/login',
    exact: true,
    isProtected: false
  },
  signup: {
    route: '/signup',
    exact: true,
    isProtected: false
  },
  editor: {
    route: '/editor/:id',
    exact: true,
    isProtected: true
  },
  forgotPassword: {
    route: '/forgotpassword',
    exact: true,
    isProtected: false
  },
  resetPassword: {
    route: '/resetpassword',
    exact: true,
    isProtected: false
  },
  unsubscribe: {
    route: '/unsubscribe',
    exact: true,
    isProtected: false
  },
  loginRedirect: {
    route: '/loginRedirect',
    exact: true,
    isProtected: false
  }
};

export type RouteKeys = keyof typeof routeConstants;

export default routeConstants;
